import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SlickSlider from 'react-slick'

import s from './styles.module.css'

function Slider({
  children,
  parentRef,
  activeIndex,
  autoplay,
  autoplaySpeed,
  speed,
  pauseOnHover,
  dots,
  dotsClass,
  arrows,
  responsive,
  fade,
  centerMode,
  centerPadding,
  lazyload,
  initialSlide,
  slidesToShow,
  // slidesToScroll,
  adaptiveHeight,
  afterChange,
  beforeChange,
  className,
}) {
  const sliderRef = useRef(null)
  const decideRef = parentRef || sliderRef
  // decideRef.current.slickGoTo(newIndex);
  useEffect(() => {
    if (decideRef.current && activeIndex !== null)
      decideRef.current.slickGoTo(activeIndex)
  }, [decideRef, activeIndex])

  const ref = {
    ...(decideRef ? { ref: decideRef.current } : null),
  }

  return (
    <SlickSlider
      {...ref}
      {...{
        pauseOnHover,
        autoplay,
        autoplaySpeed,
        speed,
        arrows,
        dots,
        dotsClass,
        responsive,
        fade,
        centerMode,
        centerPadding,
        lazyload,
        initialSlide,
        slidesToShow,
        // slidesToScroll,
        adaptiveHeight,
        afterChange,
        beforeChange,
        // eslint-disable-next-line react/display-name
        appendDots: (dots) => <ul>{dots}</ul>,
      }}
      className={classNames(s.container, className)}
    >
      {children}
    </SlickSlider>
  )
}

Slider.propTypes = {
  children: PropTypes.node,
  parentRef: PropTypes.object,
  activeIndex: PropTypes.number,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  speed: PropTypes.number,
  pauseOnHover: PropTypes.bool,
  dots: PropTypes.bool,
  dotsClass: PropTypes.string,
  arrows: PropTypes.bool,
  responsive: PropTypes.array,
  fade: PropTypes.bool,
  centerMode: PropTypes.bool,
  centerPadding: PropTypes.string,
  lazyload: PropTypes.string,
  initialSlide: PropTypes.number,
  slidesToShow: PropTypes.number,
  // slidesToScroll: PropTypes.number,
  adaptiveHeight: PropTypes.bool,
  afterChange: PropTypes.func,
  beforeChange: PropTypes.func,
  className: PropTypes.string,
}
Slider.defaultProps = {
  children: null,
  parentRef: {},
  activeIndex: null,
  autoplay: false,
  autoplaySpeed: 5000,
  speed: 750,
  pauseOnHover: true,
  dots: true,
  dotsClass: 'slick-dots',
  arrows: false,
  responsive: [],
  fade: true,
  centerMode: false,
  centerPadding: '0%',
  lazyload: 'ondemand',
  initialSlide: 0,
  slidesToShow: 1,
  // slidesToScroll: 1,
  adaptiveHeight: false,
  afterChange: null,
  beforeChange: null,
  className: '',
}

export default Slider
